@import 'variables';

* {
    font-family: 'Inter', sans-serif;
}

body {
    background-color: $main-bg;
    color: $primary-text;
}

.icon {
    margin-bottom: 1.5em;
}

.title {
    text-align: center;
    font-weight: 700;
    font-size: 32px;
    line-height: 40px;
    color: #000000;
}

.description {
    text-align: center;
    font-weight: 400;
    line-height: 18px;
    color: $primary-text;
}